body, html {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*------------Navbar -----------*/
:root{
  --primary-red: red;
}

.navbar-nav > li > a{
  border-bottom: 1px solid #0d6efd;
}

.navbar-nav > li > a:hover{
  border-bottom: 1px solid white;
}

.navbar-nav > li > a:focus{
  
  border-bottom: 1px solid white;
}

.navbar-nav > li > a:active{
  border-bottom: 1px solid white;
}
/**************Home******************/





.home-content {
 position: relative;
 background-color: #0d6efd;
 height: 100vh;
}

/***********************Particles**************/

.tsparticles-canvas-el{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;

}